<template>
  <div class="card" id="mycard">


    <div id="toolbar" class="fixed-table-toolbar">
      <div class="row">

        <label class="col-md-1 col-form-label"><h5>Process:</h5> </label>
        <div class="col-md-2">
          <select class="form-control" v-model="processcode" @change="loadData(processcode)">
            <option v-for="process in processes" v-bind:value="process.code" >
              {{ process.name }}
            </option>
          </select>
        </div>

        <div class="col-md-9">
          <div class="float-left">
            <h5 style="padding-left: 10px; padding-top: 10px; padding-bottom: 0px;"> &nbsp;Madeup's List</h5>
          </div>
          <div class="float-right search">
            <input  id="txtsearch" class="form-control" type="text" placeholder="Search" autocomplete="off">
          </div>
        </div>
        </div>
      </div>



    <div class="table-responsive">
      <table id="madeup-table"
             class="table table-borderless table-condensed"
             data-search="false"
             data-pagination="true"
             data-show-refresh="false"
             data-show-columns="false"
             data-page-list="[10, 25, 50, 100, ALL]"
             data-show-footer="false"
             data-toggle="context"
             data-target=".context-table">
        <thead>
        <tr>
          <th data-field="id" data-class="d-none">id</th>
          <th data-field="code" data-width="100">Code</th>
          <th data-field="article.name" >Article</th>
          <th data-field="design.name" >Design</th>
          <th data-field="color.name" >Color</th>
          <th data-field="quality.name" >Quality</th>
          <th data-field="style.name" >Style</th>
          <th data-field="size.name" >Size</th>
          <th data-field="weight" data-align="right" data-formatter="weightFormat">Weight</th>
        </tr>
        </thead>
        <tbody>
        </tbody>
        <tfoot>
        </tfoot>
      </table>
    </div>


    <!-- Context Menu -->
    <div id="context-menu" class="context-table">
      <div class="dropdown-menu">

        <a href="#" class="dropdown-item" @click="loadData(processcode)"><i class="icon-reset" style="color: darkgreen"></i>Refresh</a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item" ><i class="icon-gear"></i>Process List</a>

      </div>
    </div>
    <!-- End of Context Menu -->


    <vmodal name="madeup-window" transition="nice-modal-fade" :delay="100" :resizable="true" width="66%" height="68%" @before-open="beforeOpen" @before-close="beforeClose" >
      <FabricForm v-bind:mymadeup="madeup"></FabricForm>
    </vmodal>


    <vmodal name="process-window" transition="nice-modal-fade" :delay="100" :resizable="true" width="90%" height="66%" >
      <MadeupProcessForm v-bind:mymadeup="madeup"></MadeupProcessForm>
    </vmodal>

    <vmodal name="fabreq-window" transition="nice-modal-fade" :delay="100" :resizable="true" width="90%" height="66%" >
      <FabReqForm v-bind:mymadeup="madeup"></FabReqForm>
    </vmodal>

  </div>

</template>

<script>
  import FabricForm from '@/components/v1/tms/fabric/FabricForm.vue'
  import FabReqForm from '@/components/v1/tms/madeup/FabricRequirementForm.vue'
  import MadeupProcessForm from '@/components/v1/tms/madeup/MadeupProcessForm.vue'
  import FabButton from '@/components/core/FabButton.vue'
  import moment from 'moment-timezone'
  import { store } from '@/store/store.js'
  import {userService} from '@/store/auth-header.js'
  export default {
    name: 'ProcessedMadeupView.vue',
    components: {
      FabricForm,
      MadeupProcessForm,
      FabReqForm,
      FabButton
    },
    store,
    data () {
      return {
        mytable: {},
        madeup: JSON.parse('{"id":"","code":0,"status":0,"type":0,"nature":0,"name":"","p_name":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"parent":0,"article":{"id":"","code":0,"type":0,"status":0,"nature":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":0,"name":"","digits":0},"conv_fact":0,"sub_unit":{"id":"","code":0,"name":"","digits":0}},"design":{"id":"","code":0,"name":"","p_name":""},"style":{"id":"","code":0,"name":"","p_name":""},"color":{"id":"","code":0,"status":0,"name":"","p_name":""},"quality":{"id":"","code":0,"name":"","p_name":""},"size":{"id":"","code":0,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":0},"unit":{"id":"","code":0,"name":"","digits":0},"weight":0,"fabrics":[]}'),
        processes:[],
        processcode: 0
      }
    },
    created () {

    },
    mounted () {
      let self = this;

      self.$data.madeup = JSON.parse('{"id":"","code":0,"status":0,"type":0,"nature":0,"name":"","p_name":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"parent":0,"article":{"id":"","code":0,"type":0,"status":0,"nature":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":0,"name":"","digits":0},"conv_fact":0,"sub_unit":{"id":"","code":0,"name":"","digits":0}},"design":{"id":"","code":0,"name":"","p_name":""},"style":{"id":"","code":0,"name":"","p_name":""},"color":{"id":"","code":0,"status":0,"name":"","p_name":""},"quality":{"id":"","code":0,"name":"","p_name":""},"size":{"id":"","code":0,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":0},"unit":{"id":"","code":0,"name":"","digits":0},"weight":0,"fabrics":[]}');

      this.$data.mytable = $('#madeup-table');

      this.$data.mytable.bootstrapTable();
      $('[data-toggle="popover"]').popover();

      this.$data.mytable.contextmenu({
        target: '#context-menu',
        scopes: 'tbody > tr',
        onItem: function (row, e) {
          var id = $(row.children('*')[0]).text();

          if ($(e.target).text() === 'Yarn Requirements') {
            self.showYarnReqModal(id);
          }else if ($(e.target).text() === 'Process List'){
            self.showProcessModal(id);
          }else if ($(e.target).text() === 'Modify') {
            self.modifyDocument (id);
          } else if ($(e.target).text() === 'Remove') {
            self.removeDocument(id);
          }
        }
      });

      $('#txtsearch').keyup(function () {
        var val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase()
        $('#madeup-table>tbody>tr').show().filter(function () {
          var text = $(this).text().replace(/\s+/g, ' ').toLowerCase()
          return !~text.indexOf(val)
        }).hide()
      });

      self.loadProcesses();
      //self.loadData(process_code);
    },
    methods: {
      showFabricReqModal(id){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/madeup/${id}/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {
            self.$data.madeup = resp.data;
            self.$modal.show('fabreq-window');
          } else {
            swal ( { title: "Oh noes!" ,  text: resp.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text:  err.toString() , icon:  "error"} );
        });
      },
      showProcessModal(id){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        self.$data.madeup = JSON.parse('{"id":"","code":0,"status":0,"type":0,"nature":0,"name":"","p_name":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"parent":0,"article":{"id":"","code":0,"type":0,"status":0,"nature":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":0,"name":"","digits":0},"conv_fact":0,"sub_unit":{"id":"","code":0,"name":"","digits":0}},"design":{"id":"","code":0,"name":"","p_name":""},"style":{"id":"","code":0,"name":"","p_name":""},"color":{"id":"","code":0,"status":0,"name":"","p_name":""},"quality":{"id":"","code":0,"name":"","p_name":""},"size":{"id":"","code":0,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":0},"unit":{"id":"","code":0,"name":"","digits":0},"weight":0,"fabrics":[]}');

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/madeup/${id}/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {

            self.$data.madeup = resp.data;
            self.$modal.show('process-window');
          } else {
            swal ( { title: "Oh noes!" ,  text: resp.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text:  err.toString() , icon:  "error"} );
        });


      },
      closeFabricReqModal(){
        this.$modal.hide('fabreq-window');
      },
      closeProcessModal(){
        this.$modal.hide('process-window');
      },
      closeModal () {
        this.$modal.hide('madeup-window');
      },
      showModal () {
        this.$modal.show('madeup-window');
      },
      beforeOpen(){
        console.log('beforeOpen');
      },
      beforeClose(){
        this.$data.madeup = JSON.parse('{"id":"","code":0,"status":0,"type":0,"nature":0,"name":"","p_name":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"parent":0,"article":{"id":"","code":0,"type":0,"status":0,"nature":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":0,"name":"","digits":0},"conv_fact":0,"sub_unit":{"id":"","code":0,"name":"","digits":0}},"design":{"id":"","code":0,"name":"","p_name":""},"style":{"id":"","code":0,"name":"","p_name":""},"color":{"id":"","code":0,"status":0,"name":"","p_name":""},"quality":{"id":"","code":0,"name":"","p_name":""},"size":{"id":"","code":0,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":0},"unit":{"id":"","code":0,"name":"","digits":0},"weight":0,"fabrics":[]}');
      },

      async loadProcesses(){

        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        self.$data.processes = [];
        await fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/process/after/399/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {
            if(resp.data != null){
              self.$data.processes = resp.data;
            }
          } else {
            swal ( { title: "Oh noes!" ,  text: resp.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });
      },
      async loadData (process_code) {
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        $(self.$data.mytable).block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });
        self.$data.mytable.bootstrapTable('load',[]);

        await fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/madeup/process/${process_code}/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {
            if( _.isArray(resp.data)){
              self.$data.mytable.bootstrapTable('load', resp.data);
            }
          } else {
            swal ( { title: "Oh noes!" ,  text: resp.message, icon:  "error"} );
          }

          $(self.$data.mytable).unblock();
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });
      }
    }
  }
</script>

<style scoped>

  option {
    /*height:20px !important;*/
    /*margin:*/
    /*line-height:*/
    padding: 5px 0 !important;
  }
  .modal {
    text-align: center;
    padding: 0!important;
  }

  .modal:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  .modal-dialog {
    display: inline-block;
    text-align: left;
    width: 40%;
    vertical-align: middle;
  }
</style>
